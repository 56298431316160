<template>
  <div>
    <Tab :list="tabs"></Tab>

    <router-view></router-view>
    <!-- <Dialog></Dialog> -->
  </div>
</template>
<script>
import Tab from "@/components/Tab";
// import Dialog from "@/components/Dialog";
export default {
  components: {
    Tab,
    // Dialog,
  },
  data() {
    return {
      tabs: [
        {
          title: "防汛防涝",
          path: "/drainage/preventFlood/preventFlood",
        },
        {
          title: "预警参数配置",
          path: "/drainage/preventFlood/parameterDispose",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title-list-box {
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    margin-bottom: 20px;
  }
}
img {
  display: inline-block;
  width: 300px;
  margin-bottom: 30px;
  margin-right: 30px;
}
</style>
