<template>
  <div class="tab">
    <!-- includes，来包含增加编辑页面，增加编辑等页面，在当前页面后加add/edit -->
    <div
      class="tab-item"
      :class="current.includes(item.path) ? 'active' : ''"
      @click="onTabClick(item)"
      v-for="item in list"
      :key="item.path"
    >
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      //tablist
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: this.$route.path, //初始值根据url的path值
    };
  },

  methods: {
    onTabClick(item) {
      this.$router.push(item.path); //切换路由
      this.current = item.path; //修改当前tab
    },
  },
};
</script>
<style lang="less" scoped>
.tab {
  display: flex;
  align-items: center;
  // width: 1364px;
  // height: 1px;
  opacity: 0.8;
  border-bottom: 2px solid;
  border-image: linear-gradient(
      90deg,
      rgba(53, 137, 255, 1),
      rgba(53, 137, 255, 1),
      rgba(53, 137, 255, 1),
      rgba(53, 137, 255, 0)
    )
    2 2;
  margin-bottom: 2vh;

  .tab-item {
    font-size: 1.15em;
    width: 9vw;
    line-height: 2.5vw;
    text-align: center;
    font-family: YouSheBiaoTiHei;
    color: #d9dadc;
    text-shadow: 0px 2px 14px rgba(31, 208, 255, 0.6);
    cursor: pointer;
  }
  .active {
    color: #00ffff;
  }
}
.active {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 9vw;
    height: 2.5vw;
    background-image: url("tab-bg.png");
    background-size: 100% 100%;
    left: 0;
    top: 0;
  }
}
</style>
